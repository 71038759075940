import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import gsap from 'gsap';
import Image from '../components/image';
import { Title, Text } from '../components/text';
import johanna from '../images/johanna_piano.jpg';
import favicon from '../favicon.png';
import AboutPage from './old_about';
import ContactPage from './contact';

const footer = {
  flex: 1,
  marginTop: 10,
};

const IndexPage = () => {
  const [showAbout, setShowAbout] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [footerDelay, setFooterDelay] = useState(2.5);

  useEffect(() => {
    document.addEventListener('touchstart', function () {}, true);
  }, []);

  useEffect(() => {
    if (showAbout || showContact) setFooterDelay(0.17);
  }, [showAbout, showContact]);

  return (
    <Layout
      showAbout={showAbout}
      setShowAbout={setShowAbout}
      showContact={showContact}
      setShowContact={setShowContact}
    >
      <Helmet
        title={'Website terms | JOHANNA BRUN'}
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <meta
          name="description"
          content={
            "I'm Johanna Brun, a multi-talented artist and singer-songwriter from Strängnäs, Sweden. I'm currently crafting my debut album, filled with emotional expression, drawing on personal experiences and the hope of aiding others on their journey towards physical and mental health. Music has the power to evoke feelings, and that's what I strive to do."
          }
        />
        <link rel="icon" href={favicon} />
      </Helmet>

      <div style={footer}>
        <div className="footerContent">
          <div>
            <Text
              string={'Artist & singer-songwriter, Stockholm'}
              delay={footerDelay}
              show={!showAbout && !showContact}
            />
          </div>
          <div className="footerLink">
            <Text
              string={'Johanna Brun, 2023'}
              delay={footerDelay}
              show={!showAbout && !showContact}
            />
          </div>
        </div>
      </div>

      <ContactPage show={showContact} />
      <AboutPage show={showAbout} />
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <title>Website terms | JOHANNA BRUN</title>;
